<template>
    <div>
        <device-info :item="item" class="full-box" :hideStatusTag="true"></device-info>

        <div class="rect-box">
            <div class="small-gray-text talign-left">
                <div style="float: right;">调试工人：{{item.lastWorker || '-'}}</div>
                <div>调试开始时间：{{item.startDebugTime || '-'}}</div>
            </div>
            <div class="small-gray-text talign-left" style="margin: 8px 0 20px;">
                调试完成时间：{{item.endDebugTime || '-'}}
            </div>
            <div class="flex-row" style="margin-bottom: 10px;">
                <div class="filter-botton" :class="{active: tabIndex == 0}" @click="tabIndex = 0">参数合规率</div>
                <div class="filter-botton" :class="{active: tabIndex == 1}" @click="tabIndex = 1">参数准确率</div>
                <div class="filter-botton" :class="{active: tabIndex == 2}" @click="tabIndex = 2">在检票</div>
            </div>
            <info-table v-show="tabIndex == 0" :showHeader="true" :columns="complianceRateTable.columns" :data="complianceRateTable.data"></info-table>
            <info-table v-show="tabIndex == 1" :showHeader="true" :columns="accuracyRateTable.columns" :data="accuracyRateTable.data"></info-table>
            <div v-show="tabIndex == 2">
                <div v-for="(error, i) in errorList" :key="i" class="check-item round-gray-box">
                    <div class="flex-cont">
                        <div>
                            <div class="text1">{{error.level}}类故障</div>
                            <div>{{error.time}}</div>
                        </div>
                        <div>{{error.worker_name}}</div>
                    </div>
                    <div>{{error.desc}}</div>
                </div>
            </div>
        </div>

        <div class="rect-box" style="position: relative;">
            <div class="middle-black-text talign-left">调试得分</div>            
            <score-tag  v-if="item.statusName == '调试完成'"  :info="{num: item.debugScore}"
             style="position: absolute;top: 10px;right: 0;height: 36px;font-size: 13px;"></score-tag>
            <div style="margin-top: 14px;">
                <div v-for="(item, i) in stepInfos" :key="i" class="score-stepitem round-gray-box">
                    <div>
                        <div class="text1">{{item.workseq_NAME}}</div>
                        <div>油耗：{{item.diesel | formatDecimal(0)}}L  时间：{{item.preiod | formatDecimal(0)}}H</div>
                    </div>
                    <div>{{item.user_NAME}}</div>
                </div>
            </div>
        </div>

        <div class="rect-box blue-link-text" style="margin-bottom: 0;" @click="$router.push({path: '/home/debugReport/' + equipCode})">
            查看调试得分报表
        </div>
    </div>
</template>

<script>
import deviceInfo from '@/components/DeviceInfo'
import infoTable from '@/components/InfoTable'
import scoreTag from '@/components/ScoreTag'
import {getStepInfo, getEquipDetail, getEquipParamComplance, getEquipParamAccuracy, getEquipError} from '@/axios/device'
export default {
    components: {deviceInfo, infoTable, scoreTag},
    data(){
        return {
            tabIndex: 0,
            equipCode: this.$route.params.equipCode,
            item: {},
            complianceRateTable: {
                columns: [{name: "paramName", text: "考核参数"}, {name: "ruleValue", text: "合规值"}, {name: "ruleName", text: "合规规则"}, 
                {name: "resultValue", text: "考核值"}, {name: "result", text: "考核结果", formatter: function(_v){
                    if(_v == "√"){
                        return `<span style="color: #0e9f6e">${_v}</span>`
                    }else if(_v == "×"){
                        return `<span style="color: #f05252">${_v}</span>`
                    }else{
                        return `<span>${_v}</span>`
                    }
                }}],
                data: []
            },
            accuracyRateTable: {
                columns: [{name: "paramName", text: "考核参数"}, {name: "ruleName", text: "合规规则"},
                {name: "result", text: "考核结果", formatter: function(_v){
                    if(_v  == "√"){
                        return `<span style="color: #0e9f6e">${_v}</span>`
                    }else if(_v == "×"){
                        return `<span style="color: #f05252">${_v}</span>`
                    }else{
                        return `<span>${_v}</span>`
                    }
                }}],
                data: []
            },
            errorList: [],
            stepInfos: []
        }
    },
    created(){
        getEquipDetail({
            equipCode: this.equipCode
        }).then(res=>{
            this.item = res.content
        })

        getStepInfo({
            equipCode: this.equipCode
        }).then(res=>{
            this.stepInfos = res.content
        })

        getEquipParamComplance({
            equipCode: this.equipCode
        }).then(res=>{
            this.complianceRateTable.data = res.content
        })

        getEquipParamAccuracy({
            equipCode: this.equipCode
        }).then(res=>{
            this.accuracyRateTable.data = res.content
        })

        getEquipError({
            equipCode: this.equipCode
        }).then(res=>{
            this.errorList = res.content
        })
    }    
}
</script>

<style lang="scss" scoped>
    .full-box.round-box{
        margin: 0;
        border-radius: 0;
    }
    .score-stepitem{
        display: flex;
        justify-content: space-between;
        text-align: left;
        padding: 15px;
        align-items: center;
        margin: 10px 0;
        .text1{
            font-size: 17px;
            color: #333333;
            padding-bottom: 5px;
        }
    }
    .check-item{
        text-align: left;
        padding: 15px;
        .flex-cont{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
        }
        .text1{
            font-size: 17px;
            color: #333333;
            padding-bottom: 5px;
        }
    }
</style>